<template>
    <b-card title="Edit Recoil">
      <b-col cols="4">
        <b-button variant="primary" :to="{ name: 'recoil' }" size="sm">
          <feather-icon size="1x" icon="ArrowLeftIcon" /> Back to Recoil
        </b-button>
      </b-col>
      <br />
  
      <b-col cols="12">
        <b-row>
          <b-col cols="6">
            <b-form-group label="SO Number" label-for="orderNumber-input">
              <b-form-input
                id="orderNumber-input"
                v-model="form.orderNumber"
                placeholder="SO Number"
                required
                disabled
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
  
      <b-col cols="12">
        <b-row>
        </b-row>
  
        <br />
  
        <h4>Item</h4>
  
        <b-row>
          <b-col>
            <b-table :items="form.item" :fields="fieldsItem" small head-variant="dark" responsive stacked="md">
              <template v-slot:cell(itemName)="row">
              <div style="width: 210px;">
                <v-select
                  v-model="form.item[row.index].detail"
                  :options="itemsStore"
                  label="name"
                  :clearable="false"/>
              </div>
              </template>
              <template v-slot:cell(itemQuantity)="row">
                <b-form-input
                  type="number"
                  step = "1"
                  min= 0
                  v-model="form.item[row.index].quantity"
                ></b-form-input>
              </template>
              <template v-slot:cell(itemNote)="row">
                <b-form-input
                  v-model="form.item[row.index].note"
                ></b-form-input>
              </template>
              <template v-slot:cell(itemDrum)="row">
              <div style="width: 210px;">
                <v-select
                  v-model="form.item[row.index].drum"
                  :options="drumsStore"
                  label="name"
                  :clearable="false"/>
              </div>
              </template>
              <template v-slot:cell(actions)="row">
                <b-button @click="form.item.splice(row.index, 1)" variant="danger" size="sm"
                  ><feather-icon size="1x" icon="XIcon" /></b-button
                >
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              size="sm"
              variant="success"
              @click="
                form.item.push(
                  {
                    detail: '',
                    quantity: '',
                    note:'',
                    drum:'',
                  }
                )
              "
              class="mt-2 float-right"
              >Add Item</b-button
            >
          </b-col>
        </b-row>
      </b-col>
  
      <br />
  
      <b-col cols="12">
        <b-button
          variant="primary"
          class="float-right"
          @click="editRecoil"
        >
          Save
        </b-button>
      </b-col>
    </b-card>
  </template>
  
  <script>
  import vSelect from 'vue-select'
  
  export default {
    components: {
      vSelect
    },
    props: ["id"],
    data() {
      return {
        form: {
          orderNumber: '',
          item: [],
          type: '',
          props: {}
        },
        items: [],
        fieldsItem: [
          { key: "itemName", label: "Name" },
          { key: "itemQuantity", label: "Quantity" },
          { key: "itemNote", label: "Note" },
          { key: "itemDrum", label: "Drum" },
          { key: "actions" },
        ],
        departmentList: [
          "HR",
          "Marketing",
          "Accounting",
          "Production",
          "Warehouse",
          "QA",
          "Maintenance",
          "IT",
        ],
        receiverList: ["Daikin", "Sharp", "LG", "Samsung", "Others"],
      };
    },
  
    methods: {
      clearForm (){
        this.form = {}
      },
      // edit recoil function
      editRecoil() {
        let newItem = this.form.item.map( el => {
          return{
            detail: el.detail.id,
            quantity: el.quantity,
            note: el.note,
            drum: el.drum.id,
          }
        })
        this.form.props.app = this.$session.get('phibase-app');
        let newForm= {
            orderNumber: this.form.orderNumber,
            item: newItem,
            type: "Recoil",
            props: this.form.props
          }
        const toast = this.$bvToast.toast("Successfully Edit Recoil", {
              title: "Success",
              variant: "success",
              solid: true,
            });
        this.$store.dispatch("order/updateOrder",{id:this.id, data: newForm})
          .then(res => {
            setTimeout(toast, 3000) 
            this.$router.push('/recoil')
          })
          .catch((err) => {
            console.log({ err });
            this.$bvToast.toast(err.response.data.error, {
              title: "Failed",
              variant: "danger",
              solid: true,
            });
          });
      },
    },
  
    created() {
      document.title = "Edit Recoil | Tai Sin";
    },
    computed: { 
      itemsStore() {
        let getItems = this.$store.getters["item/getItem"] ? this.$store.getters["item/getItem"].filter(comp => comp.name.toUpperCase() !== comp.props.id) : []
        var filteredItems = getItems.filter((el) => {
          return (
              el.props
          )
        });
        return filteredItems;
      },
      drumsStore(){
        let getItems =  this.$store.getters["bom/getBOM"] ? this.$store.getters["bom/getBOM"].filter(comp => comp.props.type === "Mama Drum" ) :[];
        var filteredItems = getItems.filter((el) => {
          return (
              el.props
          )
        });
        return filteredItems;
      }
    },
  
    mounted() {
      // get initial data for form fields
      this.$store.dispatch('order/getById', {id : this.id})
        .then((res) => {
          var data = this.$store.getters["order/getById"]
          this.form = data;
        })
        .catch((err) => console.log({ err }));
  
      this.$store
        .dispatch("item/fetchItem")
        .catch((err) => console.log(err));
      this.$store
        .dispatch("bom/getItems")
        .catch((err) => console.log(err));
    },
  };
  </script>
  
  <style scoped>
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      -webkit-appearance: textfield;
      appearance: textfield;
  }</style>